import GlobalStyles from "./utils/GlobalStyles";
import styled from "styled-components";
import bgc from "./utils/images/bgc.jpg";
import favicon from "./utils/images/favicon.png"
import Favicon from "react-favicon";

const StyledImgWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${bgc});
  background-size: cover;
  background-position: center;
  position: relative;
`;

const StyledTitle = styled.h1`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Pirulen";
`

function App() {
  return (
    <>
      <GlobalStyles/>
      <Favicon url={favicon} />
      <StyledImgWrapper>
        <StyledTitle>Strona w budowie...</StyledTitle>
      </StyledImgWrapper>
    </>
  );
}

export default App;
